<i18n>
{
  "de": {
    "pageTitle": "Table sorting",
    "pageSubtitle": "Sorting by column header (single)",
    "identifier": "Identifikation",
    "description": "Bezeichnung",
    "year": "Jahr",
    "measure": "Massnahme",
    "costs": "Investitionskosten",
    "s12e": "Scope 1-2",
    "reductionCosts": "Vermeidungskosten",
    "actions": {
      "windows": "Fensterersatz",
      "roof": "Sanierung Dach",
      "basement": "Sanierung Kellerdecke",
      "walls": "Sanierung Fassade",
      "heating": "Heizungsersatz",
      "pv": "Installation Photovoltaik",
      "electricity": "Änderung Netzanschluss",
      "zones": "Änderung von Flächen und Nutzung"
    },
    "allMeasures": "Alle Massnahmen",
    "measuresIn": "Massnahme | Massnahmen",
    "reduction": "Reduktion",
    "inTotal": "Gesamt",
    "total": "Total",
    "reset": "Zurücksetzen",
    "exportCSV": "CSV exportieren",
    "exportXLSX": "Excel exportieren",
    "noData": "Keine Massnahmen",
    "reductionAmount": "Reduktion: {amount} kg CO₂e",
    "noChange": "Keine Veränderung",
    "increaseAmount": "Zunahme: {amount} kg CO₂e",
    "s12eInfobox": "Scope 1-2 Emissionen",
    "s12eInfoboxText": "Direkte Emissionen in t CO₂e nach der Massnahme. Die Reduktion (oder Zunahme) ist die Differenz zwischen den Scope 1-2 Emissionen vor und nach der Massnahme."
  }
}
</i18n>

<template>
  <MainLayout class="p-testing-page">
    <template #default>
      <div class="page-title">{{ $t('pageTitle') }}</div>
      <div class="page-subtitle">{{ $t('pageSubtitle') }}</div>
      <div class="costs-overview-table-container">
        <DetailList v-if="tableData.length > 0" has-header>
          <!-- Headers -->
          <template #header>
            <HeaderEntry :title="$t('identifier')" class="id" sortable :sorted="sortOrder === 'building_identifier'"
              :sort-reverse="sortReverse" @update:sort="onSortableHeaderClick('building_identifier')" />
            <HeaderEntry :title="$t('description')" class="name" sortable :sorted="sortOrder === 'building_description'"
              :sort-reverse="sortReverse" @update:sort="onSortableHeaderClick('building_description')" />
            <HeaderEntry :title="$t('year')" class="year" sortable :sorted="sortOrder === 'year'"
              :sort-reverse="sortReverse" @update:sort="onSortableHeaderClick('year')" />
            <HeaderEntry :title="$t('measure')" class="measures" />
            <HeaderEntry :title="$t('s12e')" :infobox-title="$t('s12eInfobox')" :infobox-text="$t('s12eInfoboxText')"
              :unit="`t CO₂e (${$t('reduction')})`" class="emissions" align-right sortable
              :sorted="sortOrder === 'next_s12e'" :sort-reverse="sortReverse"
              @update:sort="onSortableHeaderClick('next_s12e')" />
            <HeaderEntry :title="$t('costs')" unit="CHF" class="costs" align-right sortable
              :sorted="sortOrder === 'costs.total'" :sort-reverse="sortReverse"
              @update:sort="onSortableHeaderClick('costs.total')" />
            <HeaderEntry :title="$t('reductionCosts')" unit="CHF/kg CO₂e" class="reduction-costs" align-right sortable
              :sorted="sortOrder === 'difference_s12e.costs'" :sort-reverse="sortReverse"
              @update:sort="onSortableHeaderClick('difference_s12e.costs')" />
          </template>

          <!-- Rows, data -->
          <template>
            <li v-for="item in tableData" :key="item.id">
              <!-- ID (Identifier) -->
              <div class="id">
                <strong>
                  {{ item.building_identifier }}
                </strong>
              </div>

              <!-- Name (Description) -->
              <div class="name">
                <strong>
                  {{ item.building_description }}
                </strong>
              </div>

              <!-- Year -->
              <div class="year">
                <strong>
                  {{ item.year }}
                </strong>
              </div>

              <!-- Measures -->
              <div class="measures align-right">
                <div class="measures-list align-left">
                  <strong>
                    {{ $t('inTotal') }}
                  </strong>
                </div>
                <div v-for="(item, index) in getActionsWithCosts(item.costs)" :key="`${index}-${item.name}`"
                  class="measures-list">

                  <div class="measure-name no-wrap">
                    <div class="measure-icon" :style="{ color: item.color }">■</div>
                    {{ item.name }}
                  </div>
                </div>
              </div>

              <!-- Scope 1-2 Emissions -->
              <div class="emissions align-right">
                <strong>
                  {{ `${formatNumberWithThreshold(item.next_s12e, 2, 1, 1)} ` }}
                </strong>

                <!-- Percentage -->
                <VPopover placement="bottom" trigger="hover">
                  <span v-if="item.difference_s12e.value === 0" class="gray">{{ `(-)` }}</span>
                  <span v-else class="gray">
                    {{ `(${getDifferenceIcon(item.difference_s12e.value)}
                    ${formatNumber(item.difference_s12e.percentage_value, 1)}%)` }}
                  </span>


                  <template slot="popover">
                    <main v-if="item.difference_s12e.value > 0">
                      {{ $t('reductionAmount', { amount: formatNumber(item.difference_s12e.value, 1) }) }}
                    </main>
                    <main v-else-if="item.difference_s12e.value === 0">
                      {{ $t('noChange') }}
                    </main>
                    <main v-else>
                      {{
                        $t('increaseAmount', {
                          amount: formatNumber(Math.abs(item.difference_s12e.value), 1),
                        })
                      }}
                    </main>
                  </template>
                </VPopover>
              </div>

              <!-- Costs -->
              <div class="costs align-right">
                <div class="align-right">
                  <strong>
                    {{ formatNumber(item.costs.total, -3) }}
                  </strong>
                </div>
                <div v-for="(item, index) in getActionsWithCosts(item.costs)" :key="`${index}-${item.name}`">
                  {{ formatNumber(item.costs, -3) }}
                </div>
              </div>

              <!-- Reduction Costs -->
              <div class="reduction-costs align-right">
                <strong v-if="item.difference_s12e.costs >= 0">
                  {{ formatNumberWithThreshold(item.difference_s12e.costs, 0, 10, -1) }}
                </strong>
                <strong v-else>-</strong>
              </div>
            </li>

            <!-- Summary row -->
            <li v-if="tableData.length > 0" class="summary-row">
              <div class="id">
                <strong>
                  {{ $t('total') }}
                </strong>
              </div>
              <div class="name" />
              <div class="year" />
              <div class="measures" />
              <div class="emissions align-right" />
              <div class="costs align-right">
                <strong>
                  {{ formatNumber(summaryData.investmentCosts, -3) }}
                </strong>
              </div>
              <div class="reduction-costs align-right">
                <strong v-if="summaryData.reductionCosts > 0">
                  {{ formatNumberWithThreshold(summaryData.reductionCosts, 0, 10, -1) }}
                </strong>
                <strong v-else>
                  {{ '-' }}
                </strong>
              </div>
            </li>
          </template>
        </DetailList>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import _ from 'lodash'
import { VPopover } from 'v-tooltip'

import colorPalettes from '@/services/color-palettes.js'

import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import MainLayout from '@/pages/layouts/MainLayout.vue'

import measuresData from '@/pages/testing/table-sorting/data.json'

export default {
  name: 'testing',

  components: {
    MainLayout,
    DetailList,
    HeaderEntry,
    VPopover,
  },

  data() {
    return {
      data: measuresData,
      sortReverse: false,
      sortOrder: 'building_identifier',
      sortOptions: [
        { label: this.$t('identifier'), value: 'building_identifier', sorterFn: (a, b) => a.building_identifier.localeCompare(b.building_identifier) },
        { label: this.$t('description'), value: 'building_description', sorterFn: (a, b) => a.building_description.localeCompare(b.building_description) },
        { label: this.$t('year'), value: 'year', sorterFn: (a, b) => a.year - b.year },
        { label: this.$t('s12e'), value: 'next_s12e', sorterFn: (a, b) => a.next_s12e - b.next_s12e },
        { label: this.$t('costs'), value: 'costs.total', sorterFn: (a, b) => a.costs.total - b.costs.total },
        { label: this.$t('reductionCosts'), value: 'difference_s12e.costs', sorterFn: (a, b) => a.difference_s12e.costs - b.difference_s12e.costs },
      ],
    }
  },

  computed: {
    tableData() {
      const result =
        this.data
          // Filter by selected year if one is selected
          ?.filter((measure) => !this.selectedYear || measure.year === this.selectedYear)
          // Sort by selected option
          ?.sort((a, b) => {
            const sorter = this.sortOptions.find(option => option.value === this.sortOrder)?.sorterFn
            return this.sortReverse ? -sorter(a, b) : sorter(a, b)
          }) || []

      return result
    },

    summaryData() {
      const investmentCosts = _.sumBy(this.tableData, 'costs.total')
      const reductionCosts = _.sumBy(this.tableData, 'difference_s12e.value')

      return {
        // TODO: Add total co2 at end state (sum of end states), and co2 reduction compared to start state (sum of start states)
        investmentCosts,
        reductionCosts,
      }
    },
  },

  methods: {
    getActionsWithCosts(data) {
      const result = []
      _.keys(_.omit(data, 'total')).forEach((key) => {
        result.push({ name: this.$t(`actions.${key}`), color: colorPalettes.measureColors[key.toUpperCase()], costs: data[key] })
      })
      return result
    },

    getDifferenceIcon(value) {
      return value < 0 ? '↑' : '↓'
    },

    onSortOptionChange(sortAttribute) {
      this.sortOrder = sortAttribute
    },

    onSortableHeaderClick(sortAttribute) {
      if (this.sortOrder === sortAttribute) {
        this.sortReverse = !this.sortReverse
      } else {
        this.sortOrder = sortAttribute
        this.sortReverse = false
      }
    },

    onSortReverseChange(value) {
      this.sortReverse = value
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.p-testing-page {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);

  @media screen and (max-width: 1800px) {
    flex-direction: column;
  }

  & .page-title {
    font-size: 24px;
    font-weight: 600;
  }

  & .page-subtitle {
    font-size: 13px;
    margin-bottom: 30px;
  }

  & .costs-overview-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;

    & .overview-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h2 {
        margin: var(--spacing-m) 0;
      }
    }

    & .measures-list {
      & .measure-icon {
        position: absolute;
        left: 0;
      }

      & .measure-name {
        position: relative;
        padding-left: var(--spacing-m);
        text-align: left;
      }
    }
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .align-left {
    text-align: left;
    justify-content: flex-start;
  }

  & .no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .gray {
    color: #888;
  }

  & .no-data {
    text-align: center;
    padding: var(--spacing-m);
    border: var(--box-border);
    border-radius: var(--box-radius);
  }
}
</style>

<style lang="scss">
.p-testing-page {
  & .costs-overview-table-container {
    & .detail-list {
      overflow: hidden;
      width: 100%;
      height: 100%;
      font-size: 13px;

      & ul {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 500px;

        & li {
          display: flex;
          flex-direction: row;
          min-width: fit-content;
          width: 100%;
          align-items: stretch;

          &.summary-row {
            position: sticky;
            bottom: 0;
            background-color: var(--background-color);
          }

          &>* {
            flex: 1;
            width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          & .id {
            flex: none;
            width: 100px;
          }

          & .name {
            min-width: 160px;
          }

          & .year {
            flex: none;
            width: 60px;
          }

          & .measures {
            min-width: 180px;
          }

          & .emissions {
            display: flex;
            flex-direction: row;
            gap: var(--spacing-xxs);
          }

          & .costs,
          & .emissions,
          & .reduction-costs {
            min-width: 165px;
          }
        }
      }
    }
  }
}
</style>
